@import '../../../styles/variables.css';

.container {
  opacity: 0;
  display: block;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s;

  img {
    @mixin fill-image;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--black);
      z-index: var(--z-default);
    }
  }
}

.loaded {
  opacity: 1;
}
