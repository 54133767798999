@import '../../styles/variables.css';

.container {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--black);
  transition: opacity 0.3s, visibility 0.3s;
  z-index: var(--z-vegeta);
}

.containerActive {
  opacity: 1;
  visibility: visible;
}

.row {
  display: flex;
  /* animation: scrollLeft 100s ease forwards; */

  &:nth-child(even) {
    justify-content: flex-end;
    /* animation: scrollRight 100s ease forwards; */
  }
}

.text {
  display: block;
  margin: 9px 6px;
  @mixin font-family-menu;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 1.5px;
  white-space: nowrap;
  text-transform: uppercase;
  -webkit-text-stroke: 1px var(--white);

  @media (--mobile-portrait-up) {
    font-size: 18px;
  }

  @media (--tablet-landscape-up) {
    font-size: 22px;
    line-height: 22px;
  }
}

.textActive {
  -webkit-text-stroke: none;
  color: var(--white);
}

@keyframes scrollLeft {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
@keyframes scrollRight {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}
