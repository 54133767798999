@import '../../../styles/variables.css';

.container {
  opacity: 0;
  @mixin fill-image;
  transition: opacity 0.2s;
}

.loaded {
  opacity: 1;
}

.mobile {
  @media (--tablet-landscape-up) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--tablet-landscape-up) {
    display: block;
  }
}
