@import '../styles/variables.css';

.container {
  position: relative;
  min-height: 100vh;
  padding: 60px var(--container-padding);
  background-color: var(--black);

  @media (--tablet-landscape-up) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 var(--container-padding);
    height: calc(100vh);

    div:nth-child(2) {
      order: 1;
    }
    div:nth-child(3) {
      order: 3;
    }
  }

  :global(.react-reveal) {
    position: relative;
    z-index: var(--z-default);
  }
}

.logoWrapper {
  @media (--tablet-landscape-up) {
    order: 2;
  }
}

.logo {
  display: block;
  width: 199px;
  height: 53px;
  margin: 0 auto 50px auto;

  @media (--tablet-landscape-up) {
    width: 329px;
    height: 102px;
    margin: 20vh 0;
  }
}

.locations {
  padding-bottom: 45px;

  @media (--tablet-landscape-up) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 0;
  }
}

.location {
  @mixin btn-reset;
  display: table;
  margin: 0 auto 15px;
  @mixin font-family-menu;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--white);
  transition: all 0.2s;

  @media (--tablet-landscape-up) {
    display: block;
    margin: 20px 20px 0;
    text-align: left;
  }

  &:hover {
    color: var(--white);
    -webkit-text-stroke: none;
  }
}

.region {
  margin-bottom: 20px;
  @mixin font-family-title;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 1.2px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);

  @media (--tablet-landscape-up) {
    display: none;
  }
}

body .img1,
body .img2 {
  display: none;

  @media (--tablet-landscape-up) {
    opacity: 0;
    display: block;
    position: absolute;
    width: 50%;
    height: 50%;
    background-color: transparent;
    transition: opacity 0.3s;
    z-index: var(--z-default);
  }

  img {
    object-fit: contain;
  }
}

body .img1 {
  left: 10vw;
  bottom: 0;

  img {
    object-position: left bottom;
  }

  &.img1Top {
    top: 0;
    bottom: initial;

    img {
      object-position: left top;
    }
  }
}
.img1Top {
}

body .img2 {
  top: 0;
  right: 10vw;

  img {
    object-position: right top;
  }

  &.img2Bottom {
    top: initial;
    bottom: 0;

    img {
      object-position: right bottom;
    }
  }
}

body .imgVisible {
  opacity: 0.25;
}
